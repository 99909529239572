<template>
  <div class="modal-alert">
    <section>
      <p v-html="message"></p>
    </section>
    <footer>
      <button type="button" class="button is-white" @click="onCancel">
        {{ cancelButtonText }}
      </button>
      <button type="button" class="button is-primary" @click="onSubmit">
        {{ submitButtonText }}
      </button>
    </footer>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      default: "예",
    },
    cancelButtonText: {
      type: String,
      default: "아니오",
    },
  },
  methods: {
    onSubmit() {
      // @ts-ignore
      this.$submit(true);
    },
    onCancel() {
      // @ts-ignore
      this.$submit(false);
    },
  },
};
</script>
