export class AdminMenuAPI {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getAdminMenu = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/menu/${id}`,
      method: "get",
    })
  }
  getAdminMenuList = async () => {
    return this.axios({
      url: `/api/v1.0/admin/menu`,
      method: "get",
    })
  }
  deleteCode = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/code/${id}`,
      method: "delete",
    })
  }
  addAdminMenu = async (data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/menu`,
      method: "post",
      data
    })
  }
  modifyAdminMenu = async (id: string, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/menu/${id}`,
      method: "post",
      data
    })
  }
  deleteAdminMenu = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/menu/${id}`,
      method: "delete",
    })
  }
}
