export class DailyApi {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getDailyGameList = async (params: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/daily`,
      method: "get",
      params
    })
  }
  getDailyGame = async (date: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/daily/${date}`,
      method: "get",
    })
  }
  saveDailyGame = async (date: any, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/daily/${date}/save`,
      method: "post",
      data
    })
  }
  delDailyGame = async (date: any, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/daily/${date}/delete`,
      method: "post",
      data
    })
  }
}
