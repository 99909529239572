import { AES, enc } from "crypto-js";

interface IStorageData {
  [key: string]: any;
}

const STORAGE_ID = process.env.STORAGE_ID || "_storage";
const STORAGE_KEY = process.env.STORAGE_KEY || "zdaytS96ZkR2G!cYvQ)nU2Dj242yGb*8";

class CStorage {
  api: any
  storage: Storage
  is_prod: boolean

  constructor(storage: Storage, MODE: string) {
    this.storage = storage;
    this.is_prod = MODE === "prod"
  }

  get(key: string): any | null {
    const data = getStorageData(this.storage);
    try {
      const message = data[key];
      if (this.is_prod) {
        return JSON.parse(AES.decrypt(message, STORAGE_KEY).toString(enc.Utf8))
      } else {
        return message
      }
    } catch (e: any) {
      // console.error("[Storage] Failed to get data", e);
      return null;
    }
  }

  put(key: string, value: any): void {
    const data = getStorageData(this.storage);
    try {
      if (this.is_prod) {
        data[key] = AES.encrypt(JSON.stringify(value), STORAGE_KEY).toString()
      } else {
        data[key] = value
      }
    } catch (e: any) {
      // console.error("[Storage] Failed to put data", e);
    } finally {
      setStorageData(this.storage, data);
    }
  }

  remove(key: string): void {
    const data = getStorageData(this.storage);
    delete data[key];
    setStorageData(this.storage, data);
  }

  cleanup() {
    setStorageData(this.storage, {});
  }
}

function getStorageData(storage: Storage): IStorageData {
  const json = storage.getItem(STORAGE_ID) || "{}";
  return JSON.parse(json);
}

function setStorageData(storage: Storage, data: IStorageData): void {
  storage.setItem(STORAGE_ID, JSON.stringify(data));
}

export function useStorage() {
  const { MODE } = useRuntimeConfig().app
    return {
      session: new CStorage(sessionStorage, MODE),
      local: new CStorage(localStorage, MODE),
    }
}
