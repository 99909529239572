export class DefaultAPI {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getInfo = async () => {
    return this.axios({
      url: '/api/v1.0/.info',
      method: "get",
    })
  }
  getMenuList = async () => {
    return this.axios({
      url: '/api/v1.0/menu',
      method: "get",
    })
  }
  getServerTime = async () => {
    return this.axios({
      url: '/api/v1.0/time',
      method: "get",
    })
  }
  getHolidays = async (year: string, month: string) => {
    return this.axios({
      url: '/api/v1.0/holiday',
      method: "get",
      params: {
        year, month,
      }
    })
  }
  getCodes = async (path: string, all: any) => {
    return this.axios({
      url: '/api/v1.0/code',
      method: "get",
      params: {
        path, all
      }
    })
  }
}
