export const PROFILE = "profile";
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";

class CSession {
  api: any | null
  profile: IUser | null = null;

  constructor() {
    this.profile = useStorage().session.get(PROFILE);
  }

  setApi(api: any) {
    this.api = api
  }

  async logout() {
    try {
      await this.api.authApi.logout();
    } finally {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      useStorage().session.cleanup();
    }
  }

  async updateProfile(): Promise<void> {
    try {
      this.profile = await this.api.authApi.getProfile()
      useStorage().session.put(PROFILE, this.profile);
    } catch (e: any) {
      throw e;
    }
  }

  getProfile(): IUser | null {
    if (this.profile === null) {
      this.profile = useStorage().session.get(PROFILE);
    }
    return this.profile;
  }

  setProfile(profile: IUser): void {
    this.profile = Object.assign({}, profile);
    useStorage().session.put(PROFILE, this.profile);
  }

  clearProfile(): void {
    this.profile = null;
    useStorage().session.remove(PROFILE);
  }
}

export function useSession(): CSession {
  const { vueApp } = useNuxtApp();
  if (!vueApp._context.provides.$session) {
    vueApp.provide("$session", new CSession());
  }
  return vueApp._context.provides.$session;
}
