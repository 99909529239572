export class AdminUserApi {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getAdminUser = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/user/${id}`,
      method: "get",
    })
  }
  deleteAdminUser = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/user/${id}`,
      method: "delete",
    })
  }
  addAdminUser = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/admin/user',
      method: "post",
      data,
    })
  }
  modifyAdminUser = async (id: string, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/user/${id}`,
      method: "post",
      data,
    })
  }
  checkNewUserEmail = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/admin/user/check/email',
      method: "post",
      data
    })
  }
  checkUserEmail = async (id: string, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/user/${id}/check/email`,
      method: "post",
      data
    })
  }
}
