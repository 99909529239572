import qs from 'qs';

export class AuthAPI {
  axios: any;
  constructor(axios: any) {
    this.axios = axios
  }
  login = async (username: string, password:string) => {
    return this.axios({
      url: '/api/v1.0/login',
      method: "post",
      data: qs.stringify({
        username,
        password
      }),
      options: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    })
  }
  logout = async () => {
    return this.axios({
      url: '/api/v1.0/logout',
      method: "post",
    })
  }
  getProfile = async () => {
    return this.axios({
      url: '/api/v1.0/profile',
      method: "get",
    })
  }
}
