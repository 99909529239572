import qs from 'qs';

export class CategoryAPI {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getCategoryUsage = async () => {
    return this.axios({
      url: '/api/v1.0/admin/scratch/category-usage',
      method: "get",
    })
  }
  getCategory = async () => {
    return this.axios({
      url: '/api/v1.0/admin/scratch/category',
      method: "get",
    })
  }
  addCategory = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/admin/scratch/category',
      method: "post",
      data,
      options: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    })
  }
  modifyCategory = async (id: string, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/category/${id}`,
      method: "post",
      data,
      options: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    })
  }
  delCategory = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/category/${id}`,
      method: "delete",
    })
  }
  changeCategorySort = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/admin/scratch/category/sort',
      method: "post",
      data,
    })
  }
}
