export class AdminCodeAPI {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getAdminCode = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/code/${id}`,
      method: "get",
    })
  }
  getAdminCodeList = async (params: any) => {
    return this.axios({
      url: `/api/v1.0/admin/code`,
      method: "get",
      params
    })
  }
  addAdminCode = async (data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/code`,
      method: "post",
      data
    })
  }
  modifyAdminCode = async (id: string, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/code/${id}`,
      method: "post",
      data
    })
  }
  delAdminCode = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/code/${id}`,
      method: "delete",
    })
  }
}
