import { default as indexcDBPBJzjbtMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/authority/index.vue?macro=true";
import { default as indexk9BqeEmTPIMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/code/index.vue?macro=true";
import { default as indexLligyNj6aAMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/menu/index.vue?macro=true";
import { default as index6mxwORAfTDMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/user/index.vue?macro=true";
import { default as indexpem0tPItiaMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/index.vue?macro=true";
import { default as login9dGxfKX6ZaMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/login.vue?macro=true";
import { default as indexJ3dBvzhWAgMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/category/index.vue?macro=true";
import { default as indexJccKUNeZSrMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/daily/index.vue?macro=true";
import { default as _91id_93oX03W2rwLOMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/game/[id].vue?macro=true";
import { default as indexBPJGhc4E1xMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/game/index.vue?macro=true";
export default [
  {
    name: "admin-authority",
    path: "/admin/authority",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/authority/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-code",
    path: "/admin/code",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/code/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-menu",
    path: "/admin/menu",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-user",
    path: "/admin/user",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/admin/user/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login9dGxfKX6ZaMeta || {},
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "scratch-category",
    path: "/scratch/category",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/category/index.vue").then(m => m.default || m)
  },
  {
    name: "scratch-daily",
    path: "/scratch/daily",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/daily/index.vue").then(m => m.default || m)
  },
  {
    name: "scratch-game-id",
    path: "/scratch/game/:id()",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/game/[id].vue").then(m => m.default || m)
  },
  {
    name: "scratch-game",
    path: "/scratch/game",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/backoffice/pages/scratch/game/index.vue").then(m => m.default || m)
  }
]