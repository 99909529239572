export class AuthorityAPI {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getAuthority = async () => {
    return this.axios({
      url: '/api/v1.0/admin/authority',
      method: "get",
    })
  }
  getAuthorityItem = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/authority/${id}`,
      method: "get",
    })
  }
  deleteAuthorityItem = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/authority/${id}`,
      method: "delete",
    })
  }
  addAuthority = async (data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/authority`,
      method: "post",
      data
    })
  }
  modifyAuthority = async (id: string, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/authority/${id}`,
      method: "post",
      data
    })
  }
}
