import { defineComponent, createApp, h } from "vue";
import { v4 as uuid } from "uuid";

import Alert from "@/assets/modals/Alert.vue";
import Confirm from "@/assets/modals/Confirm.vue";

interface IModalQueueItem {
  id: string;
  close: (args?: any) => void;
}

interface IModalOptions {
  props: any;
  backdrop: boolean;
}

const DEFAULT_OPTIONS: IModalOptions = {
  props: {},
  backdrop: true,
};

class CModal {
  queue: IModalQueueItem[] = [];

  constructor() {}

  async open(component: any, options?: Partial<IModalOptions>): Promise<any> {
    const o = Object.assign({}, DEFAULT_OPTIONS, options || {});
    return new Promise((resolve) => {
      const id = uuid();
      const modal = document.createElement("div");
      modal.id = `modal_${id}`;
      modal.dataset.id = id;
      modal.classList.add("modal");

      document.body.append(modal);

      const app = createApp(
        {
          extends: component,
          methods: {
            $submit(args?: any) {
              close(args);
            },
            $close() {
              close();
            },
          },
        },
        o.props
      );
      app.mount(modal);

      const backdrop = document.createElement("div");
      backdrop.classList.add("modal-background");
      modal.prepend(backdrop);

      modal.classList.add("is-active");

      if (o.backdrop) {
        backdrop.addEventListener("click", () => close());
      }

      this.queue.push({ id, close });
      const vm = this;

      function close(args?: any) {
        app.unmount();
        modal.remove();
        const index = vm.queue.findIndex((e) => e.id === id);
        if (index !== -1) vm.queue.splice(index, 1);
        resolve(args);
      }
    });
  }

  async alert(
    message: string,
    options: Partial<{ submitButtonText: string }> = {}
  ): Promise<any> {
    return this.open(Alert, { props: { message, ...options } });
  }

  async confirm(
    message: string,
    options: Partial<{
      submitButtonText: string;
      cancelButtonText: string;
    }> = {}
  ): Promise<any> {
    return this.open(Confirm, { props: { message, ...options } });
  }
}

export function useModal(): CModal {
  const { vueApp } = useNuxtApp();
  if (!vueApp._context.provides.$modal) {
    vueApp.provide("$modal", new CModal());
  }
  return vueApp._context.provides.$modal;
}
