export class GameApi {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getGame = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/${id}`,
      method: "get",
    })
  }
  getGameList = async (page: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch`,
      method: "get",
      page
    })
  }
  addGame = async (data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch`,
      method: "post",
      data,
      options: {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
    })
  }
  modifyGame = async (id:string, data: any) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/${id}`,
      method: "post",
      data,
      options: {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
    })
  }
  delGame = async (id: string) => {
    return this.axios({
      url: `/api/v1.0/admin/scratch/${id}`,
      method: 'delete'
    })
  }
}
