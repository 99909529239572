<template>
  <div class="modal-alert">
    <section>
      <p v-html="message"></p>
    </section>
    <footer>
      <button type="button" class="button is-primary" @click="onClose">
        {{ submitButtonText }}
      </button>
    </footer>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      default: "확인",
    },
  },
  methods: {
    onClose() {
      // @ts-ignore
      this.$close();
    },
  },
};
</script>
