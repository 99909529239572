import axios from 'axios';
import { DefaultAPI } from '~/apis/DefaultAPI';
import { AuthAPI } from '~/apis/AuthAPI';
import { AuthorityAPI } from '~/apis/AuthorityAPI';
import { AdminCodeAPI } from '~/apis/AdminCodeAPI';
import { AdminMenuAPI } from '~/apis/AdminMenuAPI';
import { AdminUserApi } from '~/apis/AdminUserApi';
import { CategoryAPI } from '~/apis/scratch/CategoryApi';
import { DailyApi } from '~/apis/scratch/DailyApi';
import { GameApi } from '~/apis/scratch/GameApi';

export default defineNuxtPlugin((nuxtApp) => {
  const { API_URL, API_KEY } = useRuntimeConfig().app

  const $axios = axios.create({
    baseURL: API_URL,
    withCredentials: true,
  });

  $axios.interceptors.request.use(
    async (config) => {
      console.log('$axios.interceptors.request.use', config);
      return config;
    },
    async (error: any) => {
      return Promise.reject(error)
    }
  )

  $axios.interceptors.response.use(
    (response: any) => {
      const profile = useSession().getProfile();
      console.log('$axios.interceptors.response', profile);
      return response.data;
    },
    async (error: any) => {
      if (error?.response?.status === 401) {
        const profile = useSession().getProfile();
        console.log('$axios.interceptors.response error', profile)
        if (profile) {
          useModal().alert("세션이 만료되었습니다.");
        }
        await useSession().logout();
        useRouter().replace("/login");
      }
      return Promise.reject(error);
    }
  )

  interface API {
    authApi: AuthAPI
    authorityApi: AuthorityAPI
    defaultApi: DefaultAPI
    admincodeApi: AdminCodeAPI
    adminMenuApi: AdminMenuAPI
    adminUserApi: AdminUserApi

    categoryApi: CategoryAPI
    dailyApi: DailyApi
    gameApi: GameApi
  }

  const api: API = {
    authApi: new AuthAPI($axios),
    authorityApi: new AuthorityAPI($axios),
    defaultApi: new DefaultAPI($axios),
    admincodeApi: new AdminCodeAPI($axios),
    adminMenuApi: new AdminMenuAPI($axios),
    adminUserApi: new AdminUserApi($axios),

    categoryApi: new CategoryAPI($axios),
    dailyApi: new DailyApi($axios),
    gameApi: new GameApi($axios),
  }

  useSession().setApi(api)
  nuxtApp.vueApp.provide('globalApi', api)
})
